import React, { Component, } from "react";
import { gsap } from "../assets/gsap/all";

type SectionProbs = {
  slideEvents: {
    direction: string;
    origin: any;
    destination: any;
  };
};

export class Clients extends Component<SectionProbs> {
  debounce: any;
  staggerAnimation: any;

  componentDidMount() {
    this.staggerAnimation = gsap.to(".client", {
      opacity: 1,
      top: 0,
      // duration: 2,
      stagger: {
        each: 0.25,
        // ease: "none",
        ease: "Power1.easeOut",
      },
    });
  }

  componentDidUpdate() {
    // this.stackAnimation.restart();
    // if (this.debounce) {
    //   clearTimeout(this.debounce);
    // }

    if (this.props.slideEvents.destination?.index === 3) {
      this.debounce = setTimeout(() => {
        // ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          console.log("a");
          this.staggerAnimation.restart();
        }

        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          console.log("b");
          this.staggerAnimation.restart();
        }
      }, 300);
    }

    if (this.props.slideEvents.origin?.index === 3) {
      this.debounce = setTimeout(() => {
        //ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          console.log("c");
          this.staggerAnimation.reverse();
        }
        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          console.log("d");
          this.staggerAnimation.reverse();
        }
      }, 100);
    }
  }

  render() {
    return (
      <>
        <div className="section section-clients">
          <div id="clients-content">
            <div className="inner">
              <div>
                <h3>KUNDEN UND PROJEKTE</h3>
                <div className="client" id="axelspringer">
                  <span data-hover="WEB APPS">Web Apps</span>
                </div>
                <div className="client" id="automotive">
                  <span data-hover="IN CAR APPS">Automotive</span>
                </div>
                <div className="client" id="kas">
                  <span data-hover="MOBILE APPS">Mobile Apps</span>
                </div>
                <div className="client" id="blackboxopen">
                  <span data-hover="MULTI TOUCH APPS">Multi Touch Apps</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
