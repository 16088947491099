import React, { Component } from "react";
import { gsap, SplitText, TextPlugin } from "../assets/gsap/all";

gsap.registerPlugin(SplitText);
gsap.registerPlugin(TextPlugin);

type SectionProbs = {
  slideEvents: {
    direction: string;
    origin: any;
    destination: any;
  };
};

export class Intro extends Component<SectionProbs> {
  debounce: any;
  staggerAnimation: any;
  splitLines: any;
  handleResizeTimeout: any;

  handleResize() {
    if (this.handleResizeTimeout) {
      clearTimeout(this.handleResizeTimeout);
    }

    this.handleResizeTimeout = setTimeout(() => {
      this.unwrap();
    }, 1000);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize.bind(this));
  }

  componentDidMount() {
    window.addEventListener("resize", this.handleResize.bind(this));
    this.createAnimation();
  }

  createAnimation() {
    var text = document.getElementById("text");
    this.splitLines = new SplitText(text, {
      type: "lines",
      linesClass: "line line++",
    });

    const elements = document.querySelectorAll(".line");
    [].forEach.call(elements, (el: HTMLElement) => {
      this.wrap(el);
    });

    /*********************************
     * GSAP STAGGER ANIMATION
     *********************************/

    this.staggerAnimation = gsap.from(this.splitLines.lines, {
      y: "200%",
      stagger: {
        each: 0.25,
        ease: "Power1.easeOut",
      },
    });

    /*********************************
     * CHANGE TEXT ANIMATION
     *********************************/
    var messages = [
      "Software.",
      "Web-Apps.",
      "Mobile-Apps.",
      "Cloud-Solutions.",
      "Multi-Touch Apps.",
      "Websites.",
      "In-Car Apps."
    ];
    gsap
      .timeline({ repeat: -1 })
      // software
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[0],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        // delay: 2,
        // onComplete: scaleAnimation,
      })
      // webapps
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[1],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      })
      // mobileapps
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[2],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      })
      // multitouchapps
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[3],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      })
      // websites
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[4],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      })
      // incarapps
      .to("#text span", {
        duration: 0.25,
        text: {
          value: messages[5],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      }).to("#text span", {
        duration: 0.25,
        text: {
          value: messages[6],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      }).to("#text span", {
        duration: 0.25,
        text: {
          value: messages[0],
        },
        padSpace: true,
        delimiter: " ",
        ease: "Power1.easeOut",
        delay: 2,
      });
  }
  wrap(el) {
    const wrapper = document.createElement("div");
    wrapper.className = "line-wrapper";
    el.parentNode.insertBefore(wrapper, el);
    wrapper.appendChild(el);
  }

  unwrap() {
    var text = document.getElementById("text");
    var span = document.createElement("span");
    span.style.padding = '0px 10px'; 
    span.innerHTML = "Software.";
    if (text) {
      text.innerHTML =
        "Wir sind ein Scrum-Team aus dem Bereich Konzeption und Entwicklung und realisieren ";
      text.appendChild(span);
    }
    this.createAnimation();
  }

  componentDidUpdate() {
    // this.modalTween.reversed(this.props.reverse);

    if (this.props.slideEvents.destination?.index === 1) {
      this.debounce = setTimeout(() => {
        // ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          //this.clientAnimation.restart();
          this.staggerAnimation.restart();
          console.log("enter down");
        }

        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          // this.clientAnimation.restart();
          this.staggerAnimation.restart();
          console.log("enter up");
        }
      }, 300);
    }

    if (this.props.slideEvents.origin?.index === 1) {
      this.debounce = setTimeout(() => {
        //ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          //this.clientAnimation.reverse();
          this.staggerAnimation.reverse();
        }
        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          //this.clientAnimation.reverse();
          this.staggerAnimation.reverse();
        }
      }, 100);
    }
  }

  render() {
    return (
      <>
        <div className="section section-intro">
          <div id="content-intro">
            <div className="inner">
              <h3>ÜBER UNSER UNTERNEHMEN</h3>
              <h2 id="text">
                Wir sind ein Scrum-Team aus dem Bereich Konzeption und Entwicklung und realisieren <span style={{padding: "0px 10px"}}>Software.</span>
              </h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}
