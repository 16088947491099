import React, { Component } from "react";
import "../../scss/main.scss";
import "./privacy.scss";
import { BackButton } from "../../components/back-btn";

export class Privacy extends Component {

  componentDidMount() {
    document.getElementById("footer")!.style.position = "relative";
    document.getElementById("footer")!.style.paddingBottom = "80px";
    document.getElementById("leagals")!.style.color = "#ffffff";
  }


  render() {
    return (
      <>
        <div className="section page-privacy">
          <div id="content-privacy">
            <div className="inner">
              <BackButton></BackButton>

              <h1>DATENSCHUTZ</h1>

              <p>&nbsp;</p>
              <h4>
                <b>Verantwortlicher</b>
              </h4>
              <p>
                <strong>Freunde der Interaktion GmbH</strong>
                <br />
                Wrangelstr. 106
                <br />
                10997 Berlin, Deutschland
                <br />
                Handelsregister/Nr.: HRB 148367 B<br />
                Geschäftsführer: Andreas Sommer, Kilian Heinrich
                <br />
                &#x6d;a&#x69;&#x6c;&#64;&#x66;&#114;e&#x75;&#110;d&#x65;&#100;e&#x72;&#105;n&#x74;e&#x72;&#x61;k&#x74;&#x69;o&#x6e;&#46;d&#x65;
                <br />
                <b>Stand: 15.02.2021</b>
                <br /><br />

                <b>
                Datenschutzbeauftragter
                </b>
                <br /><br />
                Für unser Unternehmen haben wir einen Datenschutzbeauftragten bestellt. 
                Diesen erreichen Sie unter: datenschutz[at]freundederinteraktion.de
                <br /><br />

                <b>
               Ihre Rechte
                </b>
                <br /><br />
          
                Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, 
                Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu 
                erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung 
                dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur 
                Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit 
                für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten 
                Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten 
                zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der 
                zuständigen Aufsichtsbehörde zu.
                <br/> <br/> 

                Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich 
                jederzeit an unseren o.g. Datenschutzbeauftragten wenden.
          
              </p>
              <p>&nbsp;</p>

              <h4>
                <b>
                DATENSCHUTZHINWEISE FÜR WEBSEITENBESUCHER
                </b>
              </h4>
             
              <p>
              Diese Datenschutzhinweise klären Sie über die Art, den Umfang und Zweck 
              der Verarbeitung von personenbezogenen Daten beim Besuch unserer Webseite 
              auf. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich 
              identifiziert werden können. Im Rahmen des Webseitenbesuches können diese 
              z.B. sein: IP-Adresse, Geräte-ID, Betriebssystem, Browserfingerprint, 
              Meta- und Kommunikationsdaten. Wir verarbeiten Ihre Daten um eine 
              fehlerfreie Bereitstellung der Website zu gewährleisten.
              <br/> <br/> 
         
              <b>Hosting</b> 
              <br/> <br/> 

              Diese Website wird bei einem externen Dienstleister gehostet. Die 
              personenbezogenen Daten, die auf dieser Website erfasst werden, 
              werden auf den Servern des Hosters verarbeitet. 
              <br/> <br/> 

              Der Einsatz des Hosters erfolgt zum Zwecke des Webseitenbetriebs des Empfangs 
              und Versands von E-Mails. Unser Hoster wird Ihre Daten nur insoweit verarbeiten, 
              wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere 
              Weisungen in Bezug auf diese Daten befolgen. Wir einen Vertrag über 
              Auftragsverarbeitung geschlossen. Der Hoster der Seiten erhebt und speichert 
              automatisch Informationen in so genannten Server-Log-Dateien, die Ihr 
              Browser automatisch an uns übermittelt. Dies sind:
              <br/> <br/> 

              <ul>
                <li>Browsertyp und Browserversion</li>
                <li>verwendetes Betriebssystem</li>
                <li>Referrer URL</li>
                <li>Hostname des zugreifenden Rechners</li>
                <li>Uhrzeit der Serveranfrage</li>
                <li>IP-Adresse</li>
              </ul>

              <br/> <br/> 

              Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.
              <br/> <br/> 

              Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. 
              Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien 
              Darstellung und der Optimierung seiner Website – hierzu müssen die 
              Server-Log-Files erfasst werden.
              <br/> <br/>

              <b>Sicherheitsmaßnahmen</b> 
              <br/> <br/> 

              Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung 
              von Daten zwischen Ihrem Browser und unserem Server eine 
              SSL-Verschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, 
              dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt 
              und an dem Schloss-Symbol in Ihrer Browserzeile.
              <br/> <br/> 
            
              <b>Speicherdauer</b> 
              <br/> <br/> 
              Gespeicherte Daten werden gelöscht, sobald sie für ihre Zweckbestimmung 
              nicht mehr erforderlich sind und der Löschung keine gesetzlichen 
              Aufbewahrungspflichten entgegenstehen.
              </p>
              
              <p>&nbsp;</p>

              <h4>
                <b>DATENSCHUTZHINWEISE FÜR INTERESSENTEN, KUNDEN, DRITTE</b>
              </h4>

              <p>
                <b>Zwecke der Datenverarbeitung</b> 
                <br/> <br/> 

                Erhebung und Verarbeitung zu Kommunikationszwecken
                Erhebung und Verarbeitung zur Erstellung eines Angebots
                Erhebung und Verarbeitung zur Erfüllung des Vertrags
                Erhebung und Verarbeitung im Rahmen rechtlicher Verpflichtungen
              </p>
              <p>
                <b>Rechtliche Grundlagen und Speicherdauer</b> 
                <br/> <br/> 
                
                Rechtsgrundlage für die Verarbeitung Ihrer Daten ist:
                <br/> <br/> 

                Art. 6 Abs. 1 lit a DSGVO, sofern Sie uns eine Einwilligung erteilt haben. 
                Die Speicherung erfolgt solange, bis Sie als Betroffener die Löschung verlangen. 
                Art. 6 Abs. 1 lit. b DSGVO, zur Erfüllung des Vertrages oder vorvertraglicher 
                Maßnahmen. Art. 6 Abs. 1 lit. f DSGVO, aufgrund berechtigten Interesses der 
                verantwortlichen Stelle, soweit Grundrechte und Grundfreiheiten der betroffenen 
                Person dem nicht entgegenstehen.
                <br/> <br/> 

                Die Daten, die wir aufgrund Art. 6 Abs. 1 lit. b, Art. 6 Abs. 1 lit. f 
                verarbeiten werden gelöscht, sobald sie für den Zweck ihrer Verarbeitung 
                nicht mehr erforderlich sind, es sei denn, dass die Löschung aufgrund 
                gesetzliche Aufbewahrungsfristen nicht möglich und eine weitere Verarbeitung 
                gem. Artikel 6 Abs. 1 lit. c DSGVO zwingend ist oder Sie uns nach Art. 6 Abs. 
                1 lit. a DSGVO eine Einwilligung über eine darüberhinausgehende Speicherung 
                Ihrer Daten erteilt haben oder diese zur Geltendmachung, Ausübung und 
                Verteidigung von Rechtsansprüchen gem. Artikel 17 Abs. 3 lit. e DSGVO erforderlich sind.
                <br/> <br/> 

                Empfänger Ihrer personenbezogenen Daten sind: Auftragsverarbeiter, Steuerberater.
                <br/> <br/> 

                Mögliche Empfänger bei Rechtsstreitigkeiten können sein, Rechtsanwälte, 
                Inkasso-dienstleister, Staatsanwaltschaften, Gerichte oder sonstige Behörden.
                <br/> <br/> 

                Die Daten werden ausschließlich bei der verantwortlichen Stelle erhoben.
                <br/> <br/> 

                Es findet keine Datenübermittlung in Drittländer statt.
                <br/> <br/> 

                Es erfolgt keine automatisierte Entscheidungsfindung oder Profiling.
                <br/> <br/> 
              </p>

              <p>&nbsp;</p>

              <h4>
                <b>DATENSCHUTZHINWEISE FÜR BEWERBER</b>
              </h4>
              <p>
                <b>Wir weisen darauf hin, dass die Datenübertragung im Internet bei der 
                  Kommunikation per E-Mail Sicherheitslücken aufweisen kann. Daher empfehlen 
                  wir grundsätzlich keine sensiblen Daten, wie Zeugnisse, Lebensläufe, 
                  etc. unverschlüsselt per E-Mail zu versenden.
                </b> 
                <br/> <br/> 
             
                Sie haben die Möglichkeit uns eine Ende-zu-Ende-verschlüsselte E-Mail an 
                bewerbung[at]freundederinteraktion.de zu senden. 
                {/* Unser public key lautet: XUIlkj4654654654  */}
                
                <br/> <br/> 

                Falls Sie dennoch eine E-Mail versenden möchten, verschlüsseln Sie bitte die 
                Anhänge nach aktuellem technischen Stand und übermitteln uns das Kennwort auf 
                getrenntem Wege bspw. per Messenger oder Telefon. 
                <br/> <br/> 

                Bitte senden Sie Bewerbungen ausschließlich an bewerbung[at] freundederinteraktion.de. 
                <br/> <br/> 

                Wenn Sie uns eine Bewerbung zukommen lassen, verarbeiten wir Ihre damit 
                verbundenen personenbezogenen Daten (z. B. Kontakt- und Kommunikationsdaten, 
                Bewerbungsunterlagen, Notizen im Rahmen von Bewerbungsgesprächen etc.), 
                soweit dies zur Entscheidung über die Begründung eines Beschäftigungsverhältnisses 
                erforderlich ist. Rechtsgrundlage hierfür ist § 26 BDSG-neu nach deutschem Recht 
                (Anbahnung eines Beschäftigungsverhältnisses), Art. 6 Abs. 1 lit. b DSGVO 
                (allgemeine Vertragsanbahnung) und – sofern Sie eine Einwilligung erteilt 
                haben – Art. 6 Abs. 1 lit. a DSGVO. Die Einwilligung ist jederzeit widerrufbar. 
                Ihre personenbezogenen Daten werden innerhalb unseres Unternehmens ausschließlich 
                an Personen weitergegeben, die an der Bearbeitung Ihrer Bewerbung beteiligt sind.
                Sofern die Bewerbung erfolgreich ist, werden die von Ihnen eingereichten Daten 
                auf Grundlage von § 26 BDSG-neu und Art. 6 Abs. 1 lit. b DSGVO zum Zwecke der 
                Durchführung des Beschäftigungsverhältnisses in unseren Datenverarbeitungssystemen 
                gespeichert.
                <br/> <br/> 

                <b>Aufbewahrungsdauer der Daten</b> 
                <br/> <br/> 

                Sofern wir Ihnen kein Stellenangebot machen können, Sie ein Stellenangebot 
                ablehnen oder Ihre Bewerbung zurückziehen, behalten wir uns das Recht vor, 
                die von Ihnen übermittelten Daten auf Grundlage unserer berechtigten Interessen 
                (Art. 6 Abs. 1 lit. f DSGVO) bis zu 6 Monate ab der Beendigung des 
                Bewerbungsverfahrens (Ablehnung oder Zurückziehung der Bewerbung) bei uns 
                aufzubewahren. Anschließend werden die Daten gelöscht und die physischen 
                Bewerbungsunterlagen vernichtet. Die Aufbewahrung dient insbesondere Nachweiszwecken 
                im Falle eines Rechtsstreits. Sofern ersichtlich ist, dass die Daten nach Ablauf 
                der 6-Monatsfrist erforderlich sein werden (z.B. aufgrund eines drohenden oder 
                anhängigen Rechtsstreits), findet eine Löschung erst statt, wenn der Zweck für 
                die weitergehende Aufbewahrung entfällt.
                Eine längere Aufbewahrung kann außerdem stattfinden, wenn Sie eine entsprechende 
                Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) erteilt haben oder wenn gesetzliche 
                Aufbewahrungspflichten der Löschung entgegenstehen.
                <br/> <br/> 

                <b>Aufnahme in den Bewerber-Pool</b> 
                <br/> <br/> 

                Sofern wir Ihnen kein Stellenangebot machen, besteht ggf. die Möglichkeit, 
                Sie in unseren Bewerber-Pool aufzunehmen. Im Falle der Aufnahme werden alle 
                Dokumente und Angaben aus der Bewerbung in den Bewerber-Pool übernommen, 
                um Sie im Falle von passenden Vakanzen zu kontaktieren.

                Die Aufnahme in den Bewerber-Pool geschieht ausschließlich auf Grundlage 
                Ihrer ausdrücklichen Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Die 
                Abgabe der Einwilligung ist freiwillig und steht in keinem Bezug zum 
                laufenden Bewerbungsverfahren. <b>Der Betroffene kann seine Einwilligung 
                jederzeit widerrufen.</b> In diesem Falle werden die Daten aus dem Bewerber-Pool 
                unwiderruflich gelöscht, sofern keine gesetzlichen Aufbewahrungsgründe vorliegen.

                Die Daten aus dem Bewerber-Pool werden spätestens zwei Jahre nach Erteilung 
                der Einwilligung unwiderruflich gelöscht.
              </p>

              <p>&nbsp;</p>

              <h4>
                <b>DATENSCHUTZHINWEISE GENUTZTER SOZIALER NETZWERKE</b>
              </h4>

              <p>
              Wir unterhalten öffentlich zugängliche Profile in sozialen Netzwerken. 
              Die im Einzelnen von uns genutzten sozialen Netzwerke finden Sie weiter unten.
              Soziale Netzwerke wie Facebook, Twitter etc. können Ihr Nutzerverhalten 
              in der Regel umfassend analysieren, wenn Sie deren Website oder eine Website 
              mit integrierten Social-Media-Inhalten (z. B. Like-Buttons oder Werbebannern) 
              besuchen. Durch den Besuch unserer Social-Media-Präsenzen werden zahlreiche 
              datenschutzrelevante Verarbeitungsvorgänge ausgelöst. 
              <br/> <br/> 

             Im Einzelnen:
              <br/> <br/> 
              Wenn Sie in Ihrem Social-Media-Account eingeloggt sind und unsere 
              Social-Media-Präsenz besuchen, kann der Betreiber des Social-Media-Portals 
              diesen Besuch Ihrem Benutzerkonto zuordnen. Ihre personenbezogenen Daten 
              können unter Umständen aber auch dann erfasst werden, wenn Sie nicht 
              eingeloggt sind oder keinen Account beim jeweiligen Social-Media-Portal 
              besitzen. Diese Datenerfassung erfolgt in diesem Fall beispielsweise über 
              Cookies, die auf Ihrem Endgerät gespeichert werden oder durch Erfassung 
              Ihrer IP-Adresse.
              <br/> <br/> 

              Mit Hilfe der so erfassten Daten können die Betreiber der 
              Social-Media-Portale Nutzerprofile erstellen, in denen Ihre Präferenzen 
              und Interessen hinterlegt sind. Auf diese Weise kann Ihnen 
              interessenbezogene Werbung in- und außerhalb der jeweiligen 
              Social-Media-Präsenz angezeigt werden. Sofern Sie über einen Account 
              beim jeweiligen sozialen Netzwerk verfügen, kann die interessenbezogene 
              Werbung auf allen Geräten angezeigt werden, auf denen Sie eingeloggt 
              sind oder eingeloggt waren.
              <br/> <br/> 

              Bitte beachten Sie außerdem, dass wir nicht alle Verarbeitungsprozesse 
              auf den Social-Media-Portalen nachvollziehen können. Je nach Anbieter können 
              daher ggf. weitere Verarbeitungsvorgänge von den Betreibern der 
              Social-Media-Portale durchgeführt werden. Details hierzu entnehmen Sie den 
              Nutzungsbedingungen und Datenschutzbestimmungen der jeweiligen 
              Social-Media-Portale.
              </p>
             
              <p>
                <b>Rechtsgrundlage</b> 
                <br/> <br/> 
                Unsere Social-Media-Auftritte sollen eine möglichst umfassende Präsenz 
                im Internet gewährleisten. Hierbei handelt es sich um ein berechtigtes 
                Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO. Die von den sozialen 
                Netzwerken initiierten Analyseprozesse beruhen ggf. auf abweichenden 
                Rechtsgrundlagen, die von den Betreibern der sozialen Netzwerke anzugeben 
                sind (z. B. Einwilligung im Sinne des Art. 6 Abs. 1 lit. a DSGVO).
              </p>
              <p>
                <b>Verantwortlicher und Geltendmachung von Rechten</b> 
                <br/> <br/> 
                Wenn Sie einen unserer Social-Media-Auftritte (z. B. Facebook) besuchen, 
                sind wir gemeinsam mit dem Betreiber der Social-Media-Plattform für die 
                bei diesem Besuch ausgelösten Datenverarbeitungsvorgänge verantwortlich. 
                Sie können Ihre Rechte (Auskunft, Berichtigung, Löschung, Einschränkung 
                der Verarbeitung, Datenübertragbarkeit und Beschwerde) grundsätzlich sowohl 
                ggü. uns als auch ggü. dem Betreiber des jeweiligen Social-Media-Portals 
                (z. B. ggü. Facebook) geltend machen.
                <br/> <br/> 
                
                Bitte beachten Sie, dass wir trotz der gemeinsamen Verantwortlichkeit 
                mit den Social-Media-Portal-Betreibern nicht vollumfänglich Einfluss auf 
                die Datenverarbeitungsvorgänge der Social-Media-Portale haben. 
                Unsere Möglichkeiten richten sich maßgeblich nach der Unternehmenspolitik 
                des jeweiligen Anbieters.
              </p>
              <p>
                <b>Speicherdauer</b> 
                <br/> <br/> 
                Die unmittelbar von uns über die Social-Media-Präsenz erfassten Daten 
                werden von unseren Systemen gelöscht, sobald der Zweck für ihre Speicherung 
                entfällt, Sie uns zur Löschung auffordern, Ihre Einwilligung zur 
                Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt. 
                Gespeicherte Cookies verbleiben auf Ihrem Endgerät, bis Sie sie löschen. 
                Zwingende gesetzliche Bestimmungen – insb. Aufbewahrungsfristen – bleiben 
                unberührt.
                <br/> <br/> 

                Auf die Speicherdauer Ihrer Daten, die von den Betreibern der sozialen 
                Netzwerke zu eigenen Zwecken gespeichert werden, haben wir keinen Einfluss. 
                Für Einzelheiten dazu informieren Sie sich bitte direkt bei den Betreibern 
                der sozialen Netzwerke (z. B. in deren Datenschutzerklärung, siehe unten).
              </p>
              <p>
                <b>Datenübermittlung in die USA bei der Nutzung sozialer Netzwerke</b> 
                Am 16.07.2020 entschied der EuGH in seinem Urteil C-311/18, „Schrems II“, 
                dass in den USA kein angemessenes (gleichwertiges) Datenschutzniveau 
                wie in der EU vorhanden ist. 
                <br/> <br/> 

                Europäischen Tochtergesellschaften amerikanischer Dienste, wie Google, 
                sind gem. Cloud Act verpflichtet personenbezogene Daten an Ihre 
                amerikanischen Muttergesellschaften zu übermitteln, die diese wiederum 
                US-Behörden wie der NSA, aufgrund nachrichtendienstlicher Erhebungsbefugnisse 
                wie bspw. Section 702 FISA und Executive Order12 333, zur Verfügung 
                stellen müssen.
                <br/> <br/> 

                Es kann daher nicht ausgeschlossen werden, dass US-Behörden 
                (z.B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu 
                Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. 
                Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.
                <br/> <br/> 

                Eine mögliche Datenübermittlung in die USA betrifft derzeit die folgende 
                Social Networks: Facebook, Instagram, LinkedIn
                <br/> <br/> 
         
                <b>Soziale Netzwerke im Einzelnen</b> 
                <br/> <br/> 

                <b>Facebook</b> 
                <br/> <br/> 
                Wir verfügen über ein Profil bei Facebook. Anbieter dieses Dienstes ist 
                die Facebook Ireland Limited, 4 Grand Canal Square, Grand Canal Harbour, 
                Dublin 2, Irland. Die erfassten Daten werden nach Aussage von Facebook 
                auch in die USA und in andere Drittländer übertragen.
                <br/> <br/> 

                Wir haben mit Facebook eine Vereinbarung über gemeinsame Verarbeitung 
                (Controller Addendum) geschlossen. In dieser Vereinbarung wird festgelegt, 
                für welche Datenverarbeitungsvorgänge wir bzw. Facebook verantwortlich ist, 
                wenn Sie unsere Facebook-Page besuchen. Diese Vereinbarung können Sie 
                unter folgendem Link einsehen: 
                <br/> <br/> 

                <a href="https://www.facebook.com/legal/terms/page_controller_addendum.">https://www.facebook.com/legal/terms/page_controller_addendum</a>.
                Sie können Ihre Werbeeinstellungen selbstständig in Ihrem Nutzer-Account 
                anpassen. Klicken Sie hierzu auf folgenden Link und loggen Sie 
                sich ein: <a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>.
                Details entnehmen Sie der Datenschutzerklärung von 
                Facebook: <a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.

                <b>Instagram</b> 
                <br/> <br/> 

                Wir verfügen über ein Profil bei Instagram. Anbieter ist die Instagram Inc., 
                1601 Willow Road, Menlo Park, CA, 94025, USA. Details zu deren 
                Umgang mit Ihren personenbezogenen Daten entnehmen Sie der 
                Datenschutzerklärung von Instagram: <a href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a>.
                <br/> <br/> 

                <b>XING</b> 
                <br/> <br/> 

                Wir verfügen über ein Profil bei XING. Anbieter ist die New Work SE, 
                Dammtorstraße 30, 20354 Hamburg, Deutschland. Details zu deren Umgang 
                mit Ihren personenbezogenen Daten entnehmen Sie der Datenschutzerklärung 
                von XING: <a href="https://privacy.xing.com/de/datenschutzerklaerung">https://privacy.xing.com/de/datenschutzerklaerung</a>.
                <br/> <br/> 

                <b>LinkedIn</b> 
                <br/> <br/> 

                Wir verfügen über ein Profil bei LinkedIn. Anbieter ist die LinkedIn 
                Ireland Unlimited Company, Wilton Plaza, Wilton Place, Dublin 2, Irland. 
                LinkedIn verwendet Werbecookies.
                Wenn Sie LinkedIn-Werbe-Cookies deaktivieren möchten, nutzen Sie bitte 
                folgenden Link: <a href="https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">https://www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.
                Details zu deren Umgang mit Ihren personenbezogenen Daten entnehmen 
                Sie der Datenschutzerklärung von LinkedIn: <a href="https://www.linkedin.com/legal/privacy-policy">https://www.linkedin.com/legal/privacy-policy</a>.
              </p>

            </div>
          </div>
        </div>
      </>
    );
  }
}
