import React, { Component } from "react";
import "./imprint.scss";
import { BackButton } from "../../components/back-btn";

export class Imprint extends Component {


  componentDidMount() {
    document.getElementById("footer")!.style.position = "relative";
    document.getElementById("footer")!.style.paddingBottom = "80px";
    document.getElementById("leagals")!.style.color = "#ffffff";
  }

  render() {
    return (
      <>
        <div className="page page-imprint">
          <div id="content-imprint">
            <div className="inner">
              <BackButton></BackButton>
              <h1>IMPRESSUM</h1>

              <h4>Freunde der Interaktion GmbH</h4>
              <p>
                (Gesellschaft mit beschränkter Haftung) 
                <br />
                Wrangelstraße 106
                <br />
                10997 Berlin
              </p>
              <p>
                Vertretungsberechtige Geschäftsführer: Andreas Sommer, Kilian
                Heinrich
              </p>
              <p>
                E-Mail:{" "}
                <a href="&#x6d;a&#x69;&#x6c;t&#x6f;&#58;m&#x61;&#105;l&#x40;&#102;r&#x65;&#117;n&#x64;e&#x64;&#x65;r&#x69;&#x6e;t&#x65;&#114;a&#x6b;&#116;i&#x6f;&#110;.&#x64;&#101;">
                  &#x6d;&#x61;&#x69;&#x6c;&#x40;&#x66;&#x72;&#101;&#117;&#110;dederi&#x6e;&#x74;&#x65;&#x72;&#x61;&#x6b;&#x74;&#x69;&#111;&#110;.de
                </a>
              </p>
              <p>
                Registergericht: Amtsgericht Charlottenburg
                <br />
                Registernummer: HRB 148367 B
              </p>
              <p>
                Umsatzsteuer-Identifikationsnummer gem. § 27a UStG: DE 288924546
              </p>
          
              <p>
              <b>Widerspruch gegen Werbe-E-Mails</b>
              <br/><br/>
              Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten Kontaktdaten zur Übersendung von nicht ausdrücklich angeforderter Werbung und Informationsmaterialien wird hiermit widersprochen. Die Betreiber der Seiten behalten sich ausdrücklich rechtliche Schritte im Falle der unverlangten Zusendung von Werbeinformationen vor.
              </p>
            
              <p>
              <b>EU-Streitschlichtung</b>
              <br/><br/>
              Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: https://ec.europa.eu/consumers/odr.
              </p>

             
              <p>
              <b>Verbraucherstreitbeilegung/ Universalschlichtungsstelle</b>
              <br/><br/>
              Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
              </p>

           
              <p>
              <b>Haftung für Inhalte</b>
              <br/><br/>
              Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den 
              allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch 
              nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder 
              nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
              <br/><br/>

              Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach 
              den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung 
              ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung 
              möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir 
              diese Inhalte umgehend entfernen.
              </p>
  
       
              <p>
              <b>Haftung für Links</b>
              <br/><br/>
              Unsere Webseite enthält Links zu externen Websites Dritter, auf deren Inhalte wir keinen 
              Einfluss haben. Deshalb können wir für diese fremden Inhalte auch keine Gewähr 
              übernehmen. Für die Inhalte der verlinkten Seiten ist stets der jeweilige 
              Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten 
              wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. 
              Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.
              <br/><br/>
         
              Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne 
              konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei 
              Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend 
              entfernen.
              </p>

              <p>
              <b>Urheberrecht</b>
              <br/><br/>
              Die durch die Seitenbetreiber erstellten Inhalte und Werke auf diesen Seiten 
              unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung, 
              Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes 
              bedürfen der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. 
              Downloads und Kopien dieser Seite sind nur für den privaten, nicht kommerziellen 
              Gebrauch gestattet.
              <br/><br/>

              Soweit die Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, 
              werden die Urheberrechte Dritter beachtet. Insbesondere werden Inhalte Dritter 
              als solche gekennzeichnet. Sollten Sie trotzdem auf eine Urheberrechtsverletzung 
              aufmerksam werden, bitten wir um einen entsprechenden Hinweis. 
              Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Inhalte umgehend entfernen.
              </p>
            </div>
          </div>

        </div>
      </>
    );
  }
}
