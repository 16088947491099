import React, { Component } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import { Intro } from "../../sections/intro";
import { Home } from "../../sections/home";
import { Clients } from "../../sections/clients";
import { Contact } from "../../sections/contact";
import { Stack } from "../../sections/stack";
import { Team } from "../../sections/team";

import "./front.scss";

interface SlideEvents {
  origin: any;
  destination: any;
  direction: string;
}

export class Front extends Component {
  slideEvents: SlideEvents = {
    origin: undefined,
    destination: undefined,
    direction: "down",
  };

  componentDidMount() {
    document.getElementById("footer")!.style.position = "fixed";
    document.getElementById("footer")!.style.paddingBottom = "0px";
  }
  
  render() {
    return (
      <>
        <ReactFullpage
          // debug /* Debug logging */

          // fullpage options
          licenseKey={"02C9C91A-1ECE47CB-AC50670D-1D962A80"} // Get one from https://alvarotrigo.com/fullPage/pricing/
          scrollingSpeed={1000}
          navigation
          anchors={["home", "intro", "stack", "clients", "contact"]}
          // sectionSelector={SECTION_SEL}
          sectionsColor={[
            "#1C1E28", //HOME

            // "#CA4299", PINK
            // "#c6c0b9", BEIGE
            // "#606468", //INTRO
            "#FEFFFE", //INTRO

            "#42a9ca", //STACK

            // "#1C1F28", //TEAM
            "#FEFFFE", //CLIENTS
            "#1C1E28", //CONTACT
          ]}
          onLeave={(origin, destination, direction) => {
            const legals = document.getElementById("leagals");
            const dots = document.getElementById("fp-nav");
            const socialIcons = document
              .getElementsByClassName("social-media")
              .item(0) as HTMLElement;



            if (destination.index === 3 || destination.index === 1) {
              if (legals) {
                legals.style.color = "#1C1E28";
              }
              if (socialIcons) {
                socialIcons.classList.add("multiply-icons");
              }
              if (dots) {
                dots.classList.add("dark-dots");
              }
            } else {
              if (legals) {
                legals.style.color = "#ffffff";
              }
              if (socialIcons) {
                socialIcons.classList.remove("multiply-icons");
              }
              if (dots) {
                dots.classList.remove("dark-dots");
              }
            }

            this.slideEvents = { origin, destination, direction };
          }}
          render={(comp) => (
            <div className="slides">
              <ReactFullpage.Wrapper>
                <Home slideEvents={this.slideEvents}></Home>
                <Intro slideEvents={this.slideEvents}></Intro>
                <Stack slideEvents={this.slideEvents}></Stack>
                {/* <Team slideEvents={this.slideEvents}></Team> */}
                <Clients slideEvents={this.slideEvents}></Clients>
                <Contact></Contact>
              </ReactFullpage.Wrapper>
            </div>
          )}
        />
      </>
    );
  }
}
