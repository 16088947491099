import React, { Component } from "react";
import { gsap } from "../assets/gsap/all";

type SectionProbs = {
  slideEvents: {
    direction: string;
    origin: any;
    destination: any;
  };
};

export class Stack extends Component<SectionProbs> {
  debounce: any;
  staggerAnimation: any;

  componentDidMount() {
    this.staggerAnimation = gsap.from(".stackicon", {
      opacity: 0,
      scale: 0,
      stagger: {
        each: 0.1,
        ease: "Power1.easeOut",
      },
    });
  }

  componentDidUpdate() {
    // this.staggerAnimation.restart();
    // if (this.debounce) {
    //   clearTimeout(this.debounce);
    // }

    if (this.props.slideEvents.destination?.index === 2) {
      this.debounce = setTimeout(() => {
        // ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          this.staggerAnimation.restart();
        }

        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          this.staggerAnimation.restart();
        }
      }, 300);
    }

    if (this.props.slideEvents.origin?.index === 2) {
      this.debounce = setTimeout(() => {
        //ENTER DOWN
        if (this.props.slideEvents.direction === "down") {
          this.staggerAnimation.reverse();
        }
        // ENTER UP
        if (this.props.slideEvents.direction === "up") {
          this.staggerAnimation.reverse();
        }
      }, 100);
    }
  }

  render() {
    return (
      <>
        <div className="section section-stack">
          <div id="content-stack">
            <div className="inner">
              <div id="wrapper-stack">
                <h3>AUSWAHL UNSERES TECHSTACKS</h3>
                <div className="stackicons">
                  <div className="stackicon" id="react">
                    <span>REACT</span>
                  </div>
                  <div className="stackicon" id="angular">
                    <span>ANGULAR</span>
                  </div>
                  <div className="stackicon" id="vue">
                    <span>VUE</span>
                  </div>
                  <div className="stackicon" id="node">
                    <span>NODE JS</span>
                  </div>
                  <div className="stackicon" id="aws">
                    <span>AWS</span>
                  </div>
                  {/* <div className="stackicon" id="sass">
                    <span>SASS</span>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
