import React, { Component } from "react";
import { Link } from "react-router-dom";

export class Footer extends Component {
  state = { footerTop: "auto" };

  // handleResize() {
  //   this.setState({ footerTop: window.innerHeight - 50 });
  // }

  // componentWillUnmount() {
  //   window.removeEventListener("resize", this.handleResize.bind(this));
  // }

  // componentDidMount() {
  //   window.addEventListener("resize", this.handleResize.bind(this));
  //   this.handleResize();
  // }

  render() {
    return (
      <>
        <footer id="footer" style={{ top: this.state.footerTop }}>
          <div id="leagals" className="leagals">
            <Link to="/imprint">IMPRESSUM</Link>&nbsp;
            <Link to="/privacy">DATENSCHUTZ</Link>
          </div>
          <div className="social-media">
            <a
              href="https://de.linkedin.com/company/freunde-der-interaktion-gmbh"
              className="social-media-icon icon-linkedin"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
            {/* <a
              href="https://www.facebook.com/freundederinteraktion/"
              className="social-media-icon icon-facebook"
              target="_blank"
              rel="noopener noreferrer"
            ></a> */}
            <a
              href="https://www.instagram.com/freundederinteraktion/"
              className="social-media-icon icon-instagram"
              target="_blank"
              rel="noopener noreferrer"
            ></a>
          </div>
        </footer>
      </>
    );
  }
}
