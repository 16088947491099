import "./scss/main.scss";

import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { AnimatedSwitch } from "react-router-transition";

import "./App.css";
import { Front } from "./pages/front/front";
import { Imprint } from "./pages/imprint/imprint";
import { Privacy } from "./pages/privacy/privacy";
import { Error } from "./pages/error/error";

import { Footer } from "./components/footer";

function mapStyles(styles) {
  return {
    opacity: styles.opacity,
    transform: `translateY(${styles.offset}%)`,
  };
}

// child matches will...
const fadeTransition = {
  // start in a transparent, upscaled state
  atEnter: {
    opacity: 0,
    offset: 500,
  },
  // leave in a transparent, downscaled state
  atLeave: {
    opacity: 0,
    offset: 0,
  },
  // and rest at an opaque, normally-scaled state
  atActive: {
    opacity: 1,
    offset: 0,
  },
};

function App() {
  return (
    <Router>
      <div>
        <AnimatedSwitch
          atEnter={fadeTransition.atEnter}
          atLeave={fadeTransition.atLeave}
          atActive={fadeTransition.atActive}
          mapStyles={mapStyles}
          className="switch-wrapper"
        >
          <Route exact path="/" component={Front} />
          <Route exact path="/imprint" component={Imprint} />
          <Route exact path="/privacy" component={Privacy} />
          <Route exact path="/404" component={Error} />
          <Redirect to="/404" />
        </AnimatedSwitch>
        <Footer></Footer>
      </div>
    </Router>
  );
}

export default App;
