import React, { Component } from "react";
import { gsap, TweenLite, ScrambleTextPlugin } from "../assets/gsap/all";

gsap.registerPlugin(ScrambleTextPlugin);

type SectionProbs = {
  slideEvents: {
    direction: string;
    origin: any;
    destination: any;
  };
};

export class Home extends Component<SectionProbs> {

  state = { fadeClass: "fade-in" };

  componentDidMount() {
    setTimeout(() => {
      this.setState({ fadeClass: ''});

      TweenLite.to("#animate-1", 0.5, {
        scrambleText: "FREUNDE",
        onComplete: function () {
          TweenLite.to("#animate-2", 1, {
            // scrambleText: "DER INTERAKTION",
            scrambleText: { text: "DER INTERAKTION", chars: "TEIJ" },
            onComplete: function () {
              TweenLite.to("#subtitle", 0.4, {
                // ease: Power2.easeOut,
                opacity: 1,
                top: 3,
                delay: 0,
              });

              TweenLite.to("#intro-content .seperator", 0.4, {
                // ease: Power2.easeOut,
                width: "7vw",
                delay: 0,
              });
            },
          });
        },
      });
    }, 600);
  }

  render() {
    return (
      <>
        <div className="section section-home">
          <div id="intro-content" className={`${this.state.fadeClass}`}>
            <div className="inner">
              <h1>
                <span id="animate-1">FREUNDE</span>
                <br />
                <span id="animate-2">DER INTERAKTION</span>
              </h1>
              <div className="seperator"></div>
              <h2 id="subtitle">Web und App Geschichten</h2>
            </div>
          </div>
        </div>
      </>
    );
  }
}
