import React, { Component } from "react";

export class Contact extends Component {
  render() {
    return (
      <>
        <div className="section section-contact">
          <div id="contact-content">
            <div className="inner">
              <h3>KONTAKT</h3>
              <a href="&#109;&#97;&#105;&#108;&#116;&#111;&#58;&#109;&#97;&#105;&#108;&#64;&#102;&#114;&#101;&#117;&#110;&#100;&#101;&#100;&#101;&#114;&#105;&#110;&#116;&#101;&#114;&#97;&#107;&#116;&#105;&#111;&#110;&#46;&#100;&#101;" id="mail">
                <span data-hover="WIR FREUEN UNS ÜBER POST!">
                  WIR FREUEN UNS ÜBER POST!
                </span>
              </a>

              <div className="contact-locations">
                <a
                  href="https://www.google.com/maps/place/Freunde+der+Interaktion+GmbH/@52.5020979,13.4317344,17z/data=!3m1!4b1!4m5!3m4!1s0x47a84ef4a15d8027:0xc48f2b358ee7c9d6!8m2!3d52.5020979!4d13.4339231"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="ber"
                  className="plain-link"
                >
                  BERLIN
                </a>
                <span>&</span>
                <a
                  href="https://www.google.com/maps/place/Freunde+der+Interaktion+GmbH/@49.45288,11.05041,15z/data=!4m2!3m1!1s0x0:0xb792048dbe7e58ea?sa=X&ved=2ahUKEwiJ5_Lqw8PsAhXRoFwKHdUAAmoQ_BIwCnoECBEQAw"
                  target="_blank"
                  rel="noopener noreferrer"
                  id="nbg"
                  className="plain-link"
                >
                  NÜRNBERG
                </a>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
