import React, { Component } from "react";
import arrow from "../assets/btn-back.svg";
import { Link } from "react-router-dom";

const buttonStyle = {
  width: "40px",
  height: "40px",
  display: "block",
  marginTop: "30px",
  marginBottom: "50px",
  backgroundSize: "contain",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "center",
  backgroundImage: "url(" + arrow + ")",
  zIndex: 1,
};

export class BackButton extends Component {
  render() {
    return (
      <>
        <Link to="/" style={buttonStyle} className="close"></Link>
      </>
    );
  }
}
