import React, { Component } from "react";
import "../../scss/main.scss";
import "./error.scss";


export class Error extends Component {

  componentDidMount() {
    document.getElementById("footer")!.style.position = "fixed";
    document.getElementById("footer")!.style.paddingBottom = "0px";
  }


  render() {
    return (
      <>
        <div className="section 404-page">
          <div id="content-error">
            <div id="bg">
              <span>404</span>
            </div>
            <div className="inner">
              <h1>
                OH! SCHNELL ZURÜCK ZUR <a href="/">STARTSEITE</a>
              </h1>
            </div>
          </div>
        </div>
       
      </>
    );
  }
}
